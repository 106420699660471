<template>
  <div>
    <v-container
      fluid
      class="down-top-padding"
    >
      <BaseBreadcrumb
        :title="loggedInUser.firstName"
        :icon="page.icon"
        :breadcrumbs="breadcrumbs"
      />
      <v-row>
        <v-col
          sm="12"
          lg="8"
          md="12"
        >
          <v-card class="mb-5">
            <v-card-text class="pa-5">
              <v-toolbar
                flat
              >
                <v-spacer />
                <template v-if="loggedInUser.verified === true && loggedInUser.active === true && loggedInUser.userType === 'Administrator'">
                  <v-btn
                    outlined
                    class="mr-4"
                    color="red darken-2"
                    @click="archive"
                  >
                    <v-icon small>
                      mdi-delete
                    </v-icon> Archive
                  </v-btn>
                </template>
              </v-toolbar>

              <v-toolbar flat>
                <v-toolbar-title>Your Details</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-divider />
              <v-row>
                <v-col cols="4">
                  <center>
                    <v-avatar
                      color="indigo"
                      size="100"
                    >
                      <v-icon dark>
                        mdi-account-circle
                      </v-icon>
                    </v-avatar>
                  </center>
                  <h1
                    style="font-size: 1rem; text-align: center"
                    class="mt-2"
                  >
                    {{ loggedInUser.contact.givenName }}
                  </h1>
                  <p style="text-align: center">
                    {{ loggedInUser.email }}
                  </p>
                </v-col>
                <v-divider
                  vertical
                />
                <v-col cols="7">
                  <template v-if="defaultClientDetailType === 'General'">
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Given Name
                        </h3>
                        <br>
                        <p>{{ loggedInUser.contact.givenName || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Surname
                        </h3>
                        <br>
                        <p>{{ loggedInUser.contact.surname || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          User Type
                        </h3>
                        <br>
                        <p>{{ loggedInUser.role || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Mobile
                        </h3>
                        <br>
                        <p>{{ loggedInUser.contact.phone || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <h3
                          class="darken-2"
                          style="font-size: 1rem; color: grey"
                        >
                          Club
                        </h3>
                        <br>
                        <p>{{ (loggedInUser.location ? loggedInUser.location.state : 'No location specified') || "Not Provided" }}</p>
                        <v-divider />
                      </v-col>
                    </v-row>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  export default {
    name: 'Registrant',
    data () {
      return {
        page: {
          title: 'Your Roster',
          icon: 'user',
        },
        breadcrumbs: [
          {
            text: 'Users',
            disabled: false,
            to: '/user/users/',
          },
          {
            text: 'User Details',
            disabled: true,
          },
        ],
        auditHeaders: [
          {
            text: 'Reason',
            value: 'reason',
          },
          {
            text: 'Resource',
            value: 'route',
          },
          {
            text: 'Method',
            value: 'method',
          },
          {
            text: 'When',
            value: 'createdAt',
          },
        ],
        clientDetailTypes: [
          'General',
          'Location',
          'Next of Kin',
          'Representitives',
          'Career',
        ],
        defaultClientDetailType: 'General',
      }
    },
    computed: {
      ...mapGetters(['loggedInUser']),
    },
    methods: {
      back () {
        this.$router.back()
      },
      changeClientDetailsScreen (screen) {
        this.defaultClientDetailType = screen
      },
      approve () {
        this.$store.dispatch('approveRegistrant', this.registrantQuery._id)
      },
      refreshAudit () {
        this.$store.dispatch('showUserAudit')
      },
    },
  }
</script>
